<template>
  <div id="app">
    <IssueForm />
  </div>
</template>

<script>
import IssueForm from "components/issueReport/issueForm";
export default {
  components: {
    IssueForm,
  },
};
</script>
