<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    ref="inputValdiator"
    :vid="vid"
    :rules="rules"
  >
    <div class="form-group">
      <label>{{ label }}</label>
      <textarea
        type="text"
        class="form-control"
        v-model="innerValue"
        :blur="blur"
        :maxlength="counterMax"
        :success="valid"
        :danger="!valid"
        :state="errors[0] ? false : valid ? true : null"
      />

      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    counterMax: {
      type: [Object, Number],
      default: undefined,
    },
    blur: {
      type: [Object, Function],
      default: undefined,
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
  },
  data: () => ({
    innerValue: "",
  }),
  computed: {
    dynamicLength() {
      return this.innerValue.length;
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
      this.$refs.inputValdiator.setFlags({ dirty: true });
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    getErrors(error) {
      return error.join(" ");
    },
    isDanger(errors) {
      return !!errors[0];
    },
  },
};
</script>
