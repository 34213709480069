<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :vid="vid"
    :name="$attrs.label"
    :rules="rules"
  >
    <div class="form-group">
      <label>{{ label }}</label
      ><br />
      <select v-model="innerValue" placeholder="Wybierz z listy">
        <option></option>
        <option v-for="(item, index) in items" :value="item" :key="index">
          {{ item }}
        </option>
      </select>
      <span v-if="errors[0]">
        {{ errors[0] }}
      </span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
