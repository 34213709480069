import Vue from 'vue'
import App from '../modules/issueReport.vue'
import VueAxios from 'vue-axios'
import { securedAxiosInstance, plainAxiosInstance } from 'api/axios'
import TurbolinksAdapter from 'vue-turbolinks'
Vue.use(TurbolinksAdapter)
Vue.use(VueAxios, {
    secured: securedAxiosInstance,
    plain: plainAxiosInstance
})
document.addEventListener('turbolinks:load', () => {
    console.log('Vue started issue')
    let element = document.getElementById("issue_report")
    if (element) {
        const app = new Vue({
            el: '#issue_report',
            components: { App },
            mounted() {
                console.log('mounted issue raport');
            },
            template: '<App />'
        })
    }
})

