<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="vid"
    :name="$attrs.label"
    :rules="rules"
  >
    <div class="form-group">
      <input
        v-model="computedValue"
        :indeterminate.prop="indeterminate"
        type="checkbox"
        ref="input"
        @click.stop
        :disabled="disabled"
        :required="required"
        :name="name"
        :value="nativeValue"
        :true-value="trueValue"
        :false-value="falseValue"
      />
      {{ label }}

      <span v-if="errors[0]">
        {{ errors[0] }}
      </span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    value: {
      value: [String, Number, Boolean, Function, Object, Array],
      default: 0,
    },
    trueValue: {
      type: [String, Number, Boolean, Function, Object, Array],
      default: true,
    },
    falseValue: {
      type: [String, Number, Boolean, Function, Object, Array],
      default: false,
    },
    disabled: Boolean,
    required: Boolean,
    indeterminate: Boolean,
    name: "",
    nativeValue: [String, Number, Boolean, Function, Object, Array],
  },
  data() {
    return {
      newValue: this.value,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(value) {
      this.newValue = value;
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
