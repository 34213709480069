<template>
  <div id="issueForm">
    <transition name="bounce">
      <div
        class="form-box panel panel-info"
        :class="isVisible ? 'show' : 'hidden'"
      >
        <div class="panel-heading" v-if="!msgSend">Potrzebuję pomocy</div>
        <div class="panel-heading" v-else>Gotowe</div>
        <span class="closeForm" @click="toggleForm">&times;</span>
        <validation-observer
          ref="observer"
          v-slot="{ handleSubmit }"
          v-if="!msgSend"
        >
          <form @submit.prevent="handleSubmit(onSubmit)">
            <select-with-validation
              v-model="form.title"
              :items="topics"
              label="Temat"
              rules="required"
            />
            <text-area-validation
              v-model="form.content"
              label="Wiadomość"
              rules="required"
            />
            <checkbox-with-validation
              label="Powiadom mnie o rozwiązaniu"
              v-model="form.notify"
            />

            <div v-if="form.notify">
              <input-text-validation
                label="Twoj email lub telefon"
                rules="required"
              />
            </div>

            <button class="but-submit btn-block">Wyślij</button>
          </form>
        </validation-observer>
        <div v-else>Dziękujemy za przesłanie zgłoszenia.</div>
      </div>
    </transition>
    <div
      class="form-activator fixed-bottom"
      :class="!isVisible ? 'show' : 'hidden'"
    >
      <button class="but-submit" @click="toggleForm">
        <i class="fa fa-life-ring" aria-hidden="true"></i> Zgłoś problem
      </button>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Pole wymagane",
});
import InputTextValidation from "components/inputs/inputTextValidation";
import TextAreaValidation from "components/inputs/textAreaValidation";
import SelectWithValidation from "components/inputs/selectWithValidation";
import CheckboxWithValidation from "components/inputs/checkboxWithValidation";

export default {
  name: "IssueForm",
  components: {
    SelectWithValidation,
    TextAreaValidation,
    InputTextValidation,
    ValidationObserver,
    CheckboxWithValidation,
  },
  data() {
    return {
      msgSend: false,
      isVisible: false,
      topics: [
        "Nie mogę złożyć zamówienia",
        "Produkt jest niedostępny",
        "Strona nie działa poprawnie",
      ],
      form: {
        title: "",
        content: "",
        notify: false,
        email: "email",
      },
    };
  },
  methods: {
    onSubmit() {
      this.$http.plain
        .post("/issue_reports", this.form)
        .then((response) => {
          console.log("issue report send");
          this.msgSend = true;
        })
        .catch((error) => {
          console.log("error while sending issue report");
        });
      console.log("submit");
    },
    toggleForm() {
      this.isVisible = !this.isVisible;
    },
  },
};
</script>
